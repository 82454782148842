<template>
  <div>
    <div class="modal-cover"/>
    <div class="terms-modal">
      <div class="top">
        <img class="logo" src="@/assets/logo_lamacat.png" alt="">
        <img class="cancel" src="@/assets/images/icon/icon_cancel.png" alt=""
             @click="closeModal">
      </div>
      <h1 v-if="category === 'service'">이용약관</h1>
      <h1 v-else>개인정보수집방침</h1>
      <div class="terms-text-box">
        <textarea v-if="category === 'service'" v-html="service_text"/>
        <textarea v-else v-html="private_text"/>
      </div>
    </div>
  </div>
</template>

<script>
import terms_text from "@/assets/data/terms_text";

export default {
  name: "Terms",
  components: {},
  props: {
    category:String
  },
  data() {
    return {
      service_text: terms_text.service,
      private_text: terms_text.private,
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeTerms')
    }
  }
}
</script>

<style scoped>

.terms-modal {
  width: 612px;
  height: 718px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 60;
}

.terms-modal .top {
  position: relative;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.terms-modal .top .cancel {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.terms-modal h1 {
  text-align: start;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.32px;
  margin: 30px 0;
}

.terms-modal .terms-text-box {
  width: 100%;
  height: 565px;
}

.terms-modal .terms-text-box textarea {
  resize: none;
  border: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  word-break: initial;
}

</style>