<template>
  <div class="heather">
    <div class="top-line">
      <div class="text">원하는 모든 리소스는 <strong>라마켓에서💫</strong></div>
    </div>
    <div class="search-line">
      <div class="search-left">
        <div class="logo" @click="pageMove('Home')">
          <img src="@/assets/logo_lamacat.png" alt="">
        </div>
        <div class="search-container">
          <input type="search" v-model="searchText" placeholder="원하는 기능, 디자인이나 사용하는 프레임 워크를 검색하세요.(예 : android studio)"
                 v-on:keypress.enter.prevent="searching">
          <div class="search-icon" v-if="searchText === ''">
            <img src="@/assets/images/icon/icon_search.png">
          </div>
          <div class="search-icon" v-else @click="deleteSearch"><img src="@/assets/images/icon/icon_cancel.png" alt=""></div>
        </div>
      </div>
      <div class="search-right">
        <div v-if="!isLogin">
          <button class="log-in custom-btn" @click="pageMove('Login')">로그인</button>
          <button class="custom-btn" @click="pageMove('SignUp')">회원가입</button>
        </div>
        <div v-else class="user-data">
          <img src="@/assets/images/icon/icon_user.png">
          <div class="tools-title" @click="isSelect = !isSelect">
            <p><span>{{ userName }}</span>님</p>
            <img v-if="isSelect" src="@/assets/images/icon/icon_gray_up.png" alt="arrow">
            <img v-else src="@/assets/images/icon/icon_gray_down.png" alt="arrow">
          </div>
          <slide-up-down class="dropdown-box" :active="isSelect" :duration="500">
            <div class="dropdown-text" @click="dropdownEvent('myPage')">
              마이페이지
            </div>
            <div class="dropdown-line"/>
            <div class="dropdown-text" @click="dropdownEvent('logOut')">
              로그아웃
            </div>
          </slide-up-down>
        </div>
      </div>
    </div>
    <div class="menu-line" v-if="!isPurchase">
      <ul>
        <li @click="menuMove(0 )"><a :class="{active: categoryTab === 0}">모든 카테고리</a></li>
        <li @click="menuMove(1, 'plugin')"><a :class="{active: categoryTab === 1}">플러그인/라이브러리</a></li>
        <li @click="menuMove(2, 'program')"><a :class="{active: categoryTab === 2}">프로그램 패키지</a></li>
        <li @click="menuMove(3, 'design')"><a :class="{active: categoryTab === 3}">디자인</a></li>
      </ul>
    </div>
    <div class="menu-line purchase-page" v-else>
      <ul>
        <li><a :class="{active:!isPurchaseSuccess}">주문정보 확인 및 결제</a></li>
        <li><img src="../assets/images/icon/icon_gray_right.png" alt=""></li>
        <li><a :class="{active:isPurchaseSuccess}">결제완료</a></li>
      </ul>
    </div>
    <div class="bottom-line"/>
  </div>
</template>

<script>
import {signOut} from "@/firebase/firebaseAuth";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "Heather",
  components: {
    SlideUpDown
  },
  data() {
    return {
      isSelect: false,
      categoryTab: 0,
      isPurchase: false,
      isPurchaseSuccess: false,
      searchText: '',
      isLogin: false,
      userName: '',
    }
  },
  watch: {
    $route() {
      const self = this;

      if (!self.$route.params.type) self.categoryTab = 0
      self.init()

      if (self.$route.path.includes('purchase')) {
        self.isPurchase = true
        if (self.$route.path.includes('purchase/success'))
          self.isPurchaseSuccess = self
      } else {
        self.isPurchase = false
        self.isPurchaseSuccess = false
      }
    },
  },
  mounted() {
    this.init()
    window.addEventListener('click', this.closeDropdownOnClick);
  },
  methods: {
    init() {
      const userData = JSON.parse(localStorage.getItem('vuex'))
      if (userData.uid !== null) {
        this.isLogin = true
        this.userName = userData.providerData.email
      } else {
        this.isLogin = false
        this.userName = ''
      }
      if (this.$route.params.type === 'plugin') this.categoryTab = 1
      if (this.$route.params.type === 'program') this.categoryTab = 2
      if (this.$route.params.type === 'design') this.categoryTab = 3
    },
    signOut,
    deleteSearch() {
      this.searchText = ''
    },
    searching() {
      const self = this;
      if (self.searchText.trim() === '') return
      self.$router.push({name: 'ItemSearch', params: {text: self.searchText}})
    },
    pageMove(index) {
      this.$router.push({name: index})
    },
    menuMove(index, value) {
      const self = this;

      self.categoryTab = index
      if (index === 0) self.$router.push({name: 'Home'})
      else {
        self.$router.push({name: 'Category', params: {type: value}})
      }
    },
    dropdownEvent(index) {
      const self = this;
      self.isSelect = false
      if (index === 'logOut') self.signOut()
      else self.$router.push({name: 'MyPage'})
    },
    closeDropdownOnClick(event) {
      const self = this
      if (self.isSelect && !self.$el.contains(event.target)) self.isSelect = false
    }
  }
}
</script>

<style scoped>

.heather {
  width: 100%;
  z-index: 40;
}

a {
  color: inherit;
  font-weight: 400;
  letter-spacing: -0.32px;
}

a.active {
  font-weight: 700;
}

.heather .top-line .text,
.heather .search-line,
.heather .menu-line {
  width: 100%;
  max-width: 1224px;
  padding: 0 16px;
  margin: 0 auto;
  white-space: nowrap;
}

.heather .top-line {
  height: 44px;
  background: #272833;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.heather .top-line .text {
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  line-height: 15.9px;
}

.heather .top-line .text strong {
  font-weight: 800;
}

.heather .search-line {
  height: 72px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-line .search-left {
  display: flex;
  align-items: center;
}

.search-line .search-left .logo {
  line-height: 1;
  cursor: pointer;
}

.search-line .search-left .logo img {
  width: 103px;
  height: 18px;
}

.search-line .search-left .search-container {
  margin-left: 16px;
  position: relative;
}

.search-line .search-left .search-container input {
  height: 52px;
  outline: none;
  border: 1px solid #E4E4E7;
  border-radius: 20px;
  width: 700px;
  padding: 0 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
}

.search-line .search-left .search-container input:focus {
  border: 1px solid #ff8a00;
}

.search-line .search-left .search-container input::placeholder {
  color: #D4D4D8;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.32px;
}

.search-line .search-left .search-container .search-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  cursor: pointer;
  background: #ffffff;
}

.search-line .search-left .search-container .search-icon img {
  width: 24px;
  height: 24px;
}

.search-line .search-right .custom-btn {
  font-size: 14px;
  height: 40px;
  width: 83px;
  border-radius: 16px;
}

.search-line .search-right {
  display: flex;
}

.search-line .search-right .user-data {
  display: flex;
  position: relative;
  height: 24px;
  padding-right: 24px;
}

.search-line .search-right .user-data img {
  height: 24px;
  width: 24px;
}

.search-line .search-right .user-data .tools-title {
  display: flex;
  cursor: pointer;
}

.search-line .search-right .user-data .tools-title p {
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
  font-size: 14px;
}

.search-line .search-right .user-data .tools-title p span {
  font-weight: 700;
  font-size: 14px;
}

.search-line .search-right .user-data .dropdown-box {
  position: absolute;
  top: 110%;
  width: 150px;
  height: 80px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.20);
}

.search-right .user-data .dropdown-box .dropdown-line {
  height: 1px;
  width: 100%;
  background: #F4F4F5;
}

.search-right .user-data .dropdown-box .dropdown-text {
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #A1A1AA;
  text-align: right;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.26px;
  cursor: pointer;
}

.search-line .search-right .user-data .user-event img {
  position: absolute;
  top: 0;
  right: 0;
}

.search-line .search-right button {
  width: 90px;
}

.search-line .search-right .log-in {
  background: #ffffff;
  color: #000000;
  font-weight: 500;
  margin-right: 16px;
}

.heather .menu-line {
  background: #ffffff;
  height: 52px;
  display: flex;
}

.heather .menu-line ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.heather .menu-line li {
  margin-right: 40px;
  font-size: 14px;
}

.heather .purchase-page li {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #71717A;
}

.heather .purchase-page .active {
  font-weight: 700;
  color: #FF8A00;

}

.heather .purchase-page img {
  width: 24px;
  height: 24px;
}

.heather .bottom-line {
  height: 1px;
  background: #E4E4E7;
}

</style>