<template>
  <div class="footer-frame">
    <div class="footer-line"/>
    <div class="footer">
      <div class="footer-container">
        <div class="footer-left">
          <h1>Lamacat</h1>
          <div class="text-box">
            <p>주식회사 지코드</p>
            <p>대표 : 황지창 ㅣ 개인정보관리 책임자 : 임산</p>
            <p>사업자등록번호 : 358- 29 - 00934 ㅣ 통신판매업신고 : 2023-서울구로-2587</p>
            <p>서울시 구로구 경인로 53길 90 STX-W 타워 1714</p>
          </div>
        </div>
        <div class="footer-center">
          <div class="text-box">
            <p>고객센터</p>
            <p><span>070-1234-5678</span></p>
            <p><span>aiden@jicode.co.kr</span></p>
            <p><span>10:30~18:00</span> (주말, 공휴일 휴무)</p>
          </div>
        </div>
        <div class="footer-right">
          <div class="text-box">
            <p>지원</p>
            <p class="click-btn" @click="showTerms('service')">이용약관</p>
            <p class="click-btn" @click="showTerms('private')">개인정보처리방침</p>
            <p class="click-btn">자주 묻는 질문</p>
          </div>
        </div>
      </div>
      <p class="copyright">Copyright © 2023 JICODE Inc. All rights reserved.</p>
    </div>
    <Terms v-if="isTerms" :category=termsCategory @closeTerms="closeTerms"/>
  </div>
</template>

<script>
import Terms from "@/components/Terms.vue";

export default {
  name: "Footer",
  data() {
    return {
      isTerms: false,
      termsCategory: ''
    }
  },
  components: {
    Terms
  },
  methods: {
    showTerms(index) {
      const self = this;
      self.termsCategory = index
      self.isTerms = true
    },
    closeTerms() {
      const self = this;
      self.isTerms = false
      self.termsCategory = ''
    }
  }
}
</script>

<style scoped>

.footer-frame {
  /*position: fixed;*/
  /*bottom: 0;*/
  width: 100%;
  background: #ffffff;
  z-index: 40;
}

.footer-line {
  height: 1px;
  background: #E4E4E7;
}

.footer {
  padding: 60px 0;
}

.footer .footer-container,
.footer .copyright {
  max-width: 1224px;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
}

.footer .footer-container {
  margin-bottom: 20px;
  display: flex;
}

.footer .footer-container .footer-left,
.footer .footer-container .footer-center,
.footer .footer-container .footer-right {
  text-align: start;
  white-space: nowrap;
}

.footer .footer-container .footer-center,
.footer .footer-container .footer-right {
  display: flex;
  align-items: flex-end;
  margin-left: 60px;
}

.footer-container .footer-left {
  width: 100%;
  max-width: 691px;
}

.footer-container .footer-left h1 {
  color: #A1A1AA;
  font-family: Tium;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.52px;
  margin-bottom: 30px;
}

.footer-container .text-box p span {
  font-family: Poppins;
}

.footer-container .text-box p {
  color: #A1A1AA;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  margin: 0;
}

.footer-container .text-box .click-btn {
  cursor: pointer;
}

.footer-container .text-box p:first-child {
  color: #000000;
  margin-bottom: 10px;
}

.footer-container .footer-center {

}

.footer .copyright {
  color: #D4D4D8;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
  text-align: start;
}
</style>