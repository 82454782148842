<template>
  <div class="layout">
    <Heather/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>

import Heather from "@/components/Heather.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "CommonView",
  components: {
    Heather,Footer
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    init() {
    },
  }
}
</script>

<style scoped>

.layout {
}

</style>
