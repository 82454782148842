export default {
    service:
        '본 약관에서 다루는 내용\n' +
        '본 서비스 약관을 확인하는 것이 번거로울 수 있다는 점은 이해하지만, 귀하가 Google 서비스를 사용하면서 Google에 기대할 수 있는 부분과 Google이 귀하에게 기대하는 부분을 명확히 해 두는 것은 중요합니다.\n' +
        '본 서비스 약관에는 Google의 사업 운영 방식, Google에 적용되는 법률, Google이 항상 진실이라고 여기는 특정 내용이 반영되어 있습니다. 따라서 귀하가 Google 서비스와 상호작용하면 본 서비스 약관을 근거로 Google과의 관계가 정의됩니다. 예를 들어, 약관에는 다음과 같은 제목의 주제들이 포함됩니다.\n' +
        'Google에 기대할 수 있는 사항. Google이 서비스를 제공 및 개발하는 방법을 설명합니다.\n' +
        'Google이 귀하에게 기대하는 사항. Google 서비스 사용과 관련된 일정한 규칙들을 정합니다.\n' +
        'Google 서비스 내 콘텐츠. Google 서비스에서 찾을 수 있는 콘텐츠의 지적 재산권에 대해 설명하며, 이러한 콘텐츠의 소유권이 귀하, Google 또는 다른 사람에게 있는지 설명합니다.\n' +
        '문제 또는 의견 충돌이 있는 경우. 귀하가 갖는 기타 법적 권리와 타인이 본 약관을 위반했을 때를 대비해 알아 두어야 할 사항을 설명합니다.\n' +
        'Google 서비스를 이용함으로써 귀하는 본 약관에 동의하게 되므로 본 약관을 숙지하는 것이 중요합니다.\n' +
        '본 약관 외에도 Google은 개인정보처리방침을 게시합니다. 개인정보처리방침은 본 약관에 포함되지는 않지만 정보의 업데이트, 관리, 내보내기 및 삭제 방법을 더 잘 이해하기 위해 읽어 볼 것을 권장합니다.\n' +
        '약관\n' +
        '서비스 제공자\n' +
        'Google 서비스를 제공하고 귀하와 계약을 체결하는 주체는 다음과 같습니다.\n' +
        'Google LLC미국 델라웨어주 법률에 따라 설립되고 미국법에 따라 운영됨1600 Amphitheatre ParkwayMountain View, California 94043USA\n',
    private:
        '본 약관에서 다루는 내용\n' +
        '본 서비스 약관을 확인하는 것이 번거로울 수 있다는 점은 이해하지만, 귀하가 Google 서비스를 사용하면서 Google에 기대할 수 있는 부분과 Google이 귀하에게 기대하는 부분을 명확히 해 두는 것은 중요합니다.\n' +
        '본 서비스 약관에는 Google의 사업 운영 방식, Google에 적용되는 법률, Google이 항상 진실이라고 여기는 특정 내용이 반영되어 있습니다. 따라서 귀하가 Google 서비스와 상호작용하면 본 서비스 약관을 근거로 Google과의 관계가 정의됩니다. 예를 들어, 약관에는 다음과 같은 제목의 주제들이 포함됩니다.\n' +
        'Google에 기대할 수 있는 사항. Google이 서비스를 제공 및 개발하는 방법을 설명합니다.\n' +
        'Google이 귀하에게 기대하는 사항. Google 서비스 사용과 관련된 일정한 규칙들을 정합니다.\n' +
        'Google 서비스 내 콘텐츠. Google 서비스에서 찾을 수 있는 콘텐츠의 지적 재산권에 대해 설명하며, 이러한 콘텐츠의 소유권이 귀하, Google 또는 다른 사람에게 있는지 설명합니다.\n' +
        '문제 또는 의견 충돌이 있는 경우. 귀하가 갖는 기타 법적 권리와 타인이 본 약관을 위반했을 때를 대비해 알아 두어야 할 사항을 설명합니다.\n' +
        'Google 서비스를 이용함으로써 귀하는 본 약관에 동의하게 되므로 본 약관을 숙지하는 것이 중요합니다.\n' +
        '본 약관 외에도 Google은 개인정보처리방침을 게시합니다. 개인정보처리방침은 본 약관에 포함되지는 않지만 정보의 업데이트, 관리, 내보내기 및 삭제 방법을 더 잘 이해하기 위해 읽어 볼 것을 권장합니다.\n' +
        '약관\n' +
        '서비스 제공자\n' +
        'Google 서비스를 제공하고 귀하와 계약을 체결하는 주체는 다음과 같습니다.\n' +
        'Google LLC미국 델라웨어주 법률에 따라 설립되고 미국법에 따라 운영됨1600 Amphitheatre ParkwayMountain View, California 94043USA\n'
};
